import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {},
    headerWrapper: {
        paddingTop: theme.spacing(3.75),
        paddingBottom: theme.spacing(3.75),
    },
    infoNotice: {
        background: theme.palette.info.main,
        color: theme.palette.background.default,
        padding: theme.spacing(2),
        margin: theme.spacing(4, 0),
        fontWeight: 600,
        textAlign: 'center',
    },
}));
