import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';

import PageActivityCovid19Layout from '$landing-page/layouts/page-activity-covid-19-layout';

export const pageQuery = graphql`
    query pageActivityPage($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                layout
                title
                description
            }
        }
    }
`;

const PageActivityPage = (props) => {
    const {
        location,
        data: { markdownRemark: entry },
    } = props;

    let Layout = null;
    // TODO: if the layout is not "covid-19", the Layout component would be null
    if (entry.frontmatter.layout === 'covid-19') {
        Layout = PageActivityCovid19Layout;
    }

    return (
        <Layout pathname={location.pathname} title={entry.frontmatter.title} description={entry.frontmatter.description}>
            {/* eslint-disable-next-line react/no-danger */}
            <article dangerouslySetInnerHTML={{ __html: entry.html }} />
        </Layout>
    );
};

PageActivityPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                layout: PropTypes.string,
                title: PropTypes.string,
                description: PropTypes.string,
            }),
            html: PropTypes.string,
        }),
        pattern: PropTypes.oneOfType([PropTypes.object]),
    }).isRequired,
};

export default PageActivityPage;
