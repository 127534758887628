import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import PageSection from '$landing-page/components/page-section';
import PageWrapper from '$landing-page/components/page-wrapper';

import useStyles from './styles';

const query = graphql`
    query pageActivityCovid19Layout {
        logo: file(relativePath: { eq: "page-activity-covid-19-logo.png" }) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 90, quality: 100, placeholder: NONE)
            }
        }
    }
`;

const PageActivityCovid19Layout = (props) => {
    const { children, pathname, title, description } = props;

    const queryData = useStaticQuery(query);
    const classes = useStyles();
    const theme = useTheme();
    const isScreenSizeMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <PageWrapper pageMetaData={{ title, description, pathname }} showFooter={false}>
            <PageSection className={classes.headerWrapper}>
                <Grid
                    container
                    spacing={isScreenSizeMd ? 5 : 2}
                    wrap={isScreenSizeMd ? 'nowrap' : 'wrap'}
                    alignItems="center"
                    justify={isScreenSizeMd ? 'flex-start' : 'center'}
                >
                    <Grid item>
                        <GatsbyImage
                            image={queryData.logo.childImageSharp.gatsbyImageData}
                            alt="Ethica Data Logo"
                            loading="eager"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h1" color="textSecondary">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </PageSection>
            <PageSection>{children}</PageSection>
            <PageSection>
                <Typography variant="subtitle2" component="p" className={classes.infoNotice}>
                    All information, data and medias are from https://www.canada.ca/en/public-health.
                </Typography>
            </PageSection>
        </PageWrapper>
    );
};

PageActivityCovid19Layout.defaultProps = {
    description: null,
};

PageActivityCovid19Layout.propTypes = {
    children: PropTypes.node.isRequired,
    pathname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
};

export default PageActivityCovid19Layout;
